<template>
  <div style="width: 100%;">
      <div class="header">
        <el-button type="primary" icon="el-icon-plus" v-preventReClick @click="createTemplate()" >新建短信模版</el-button>
      </div>
      <el-form id="list"  v-loading="pictLoading">
        <el-row class="grid">
          <el-col :span="7"  v-for="(item, index) in templates" :key="item.id" :offset="index > 0 ? 1 : 0" style="margin-top: 30px">
            <el-card shadow="hover"> 
              <el-form-item prop="template_id">
                <el-input v-model="item.template_id" class="id" readonly prefix-icon="el-icon-user-solid"> </el-input>
              </el-form-item>
              <el-form-item prop="template_title">
                <el-input  v-model="item.template_title" placeholder="模版标题"
                  style="float: right;width: 50%;position: absolute;top:-72px; margin-top:30px;  right: 5px;">
                </el-input>
              </el-form-item>
              <el-form-item prop="template_content,template_signature">
                <div style=" weight: 100%; height: 200px;  margin: auto; border: 1px solid #cae1ff; overflow: auto;  border-radius: 10px;">
                  <div class="roundrect-bot" >{{ item.template_signature }} {{ item.template_content }}</div>
                </div>
              </el-form-item>
              <el-form-item><span class="date">最后编辑:{{ item.edit_date }}</span>
              </el-form-item>
              <el-form-item prop="template_status">
                <span v-if="item.template_status =='0'" style="color:##E6A23C;">{{item.template_status_msg}} </span>
                <span v-if="item.template_status =='1'" style="color:#409EFF;" >{{item.template_status_msg}} </span>
                <span v-if="item.template_status =='2'" style="color:#67C23A;"> {{item.template_status_msg}}</span>
                <el-popover
                  placement="top-start"
                  title="提示"
                  width="200"
                  trigger="hover">
                  <slot>{{ item.reject_reason }}</slot>
                  <span v-if="item.template_status =='3'" style="color:#F56C6C;" slot="reference"> {{item.template_status_msg}}</span>
                </el-popover>
              </el-form-item>
              <el-form-item class="icon">
                <el-tooltip content="编辑" placement="bottom" >
                  <el-button class="size-icon" type="text"><i class="el-icon-edit" @click="handleClick(index)"></i></el-button>
                </el-tooltip>
              </el-form-item>
            </el-card>
          </el-col>
        </el-row>
      </el-form>
    </div>
</template>
<script>
import {errorAlert,successAlert}from "../../../utils/alert"
export default {
  data() {
    return {
      code:'',
      template_title: "",
      templates: [],
      template_status: [],
      template_status_msg:'',
      pictLoading:false,
    };
  },
  created() {
     let userInfo = sessionStorage.getItem("userInfo");
     if(userInfo){
         console.log("我有userInfo了，可以请求列表数据了");
        this.getTemplateList();
     }
    this.pictLoading = true
  },
  methods: {

    handleClick(index) {
      let row = this.templates[index];
      window.openModal(
        () => import("./create-template.vue"),
        { row: { ...row } },
        { success: () => {
          setTimeout(()=>{
            this.getTemplateList();
          },1000)
        } }
      );
    },
   
    createTemplate(row) {
      window.openModal(
        () => import("./create-template.vue"),
        { row },
       { success: () => {
         setTimeout(()=>{
            this.getTemplateList();
          },1000)
       } }
      );
    },
      
    async getTemplateList() {
      const res = await this.$http.get("/message_template/list");
        this.pictLoading = false    
      if(res.data.code==0){
         this.templates = res.data.data.templates;
      }else{
        errorAlert(res.data.msg)
      }
    },
  },

  watch:{
    templates(){
    }
  },

   beforeDestroy() {
      clearTimeout(this.timer)
    }
};
</script>

<style scoped>
#list{
  background: #ffffff;
}
span{
  font-size: 12px;
  font-weight: 400;
  margin-top: 5px;
  margin-right: -10px;
}
.text-wrapper {
  white-space: pre-wrap;
}
.header {
  position: absolute;
  right: 54px;
  top: 94px;

  
}
.el-col-7 {
  width: 32.6%;
}
.el-col-offset-1 {
  margin-left: 0.166667%;
}

.el-card {
  margin-left: 20px;
  border-radius: 10px;
  border: 1px solid #cae1ff;
  transition: all .5s;
}
.el-input__inner {
  border-color: #cae1ff;
}
.id {
  width: 37%;
  height: 30px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.el-card__body{
  padding-bottom: 0px !important;
}
.edit_date .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 0px;
  position: absolute;
  right: -25px;
  top: -46px;
  width: 156px;
  color: #999;
}

.size-icon {
  font-size: 22px;
  margin-top: -7px;
}
.delete-icon {
  font-size: 20px;
  float: right;
}

.el-form-item--small.el-form-item {
  margin-bottom: 10px;
  margin-top: -7px;
}
.el-form-item--small .el-form-item__content {
  line-height: 17px;
  
}
.el-form-item__label {
  font-size: 12px;
  right: 30px;
}
.date {
  font-size: 12px;
  color: #666;
  position: absolute;
  right: 4px;
}

.roundrect-bot {
  background: #cae1ff;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  position: relative;
  word-break:break-all;
  padding: 14px;
  margin: 20px 30px;
  line-height: 20px;
  color: #000;
}
.roundrect-bot::after {
  position: absolute;
  left: -20px;
  top: 30px;
  font-size: 40px;
}
</style>

